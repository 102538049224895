.header {
    width: 100%;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    background-color: #39445a;
    font-family: "Montserrat", sans-serif;
    font-size: 5vw;
    padding-bottom: 15px;
    box-shadow: 0px 1px 5px black;
    color: white;
    z-index: 100;

}

.loginName {
    color: #fff;
    z-index: 2000;
    position: fixed;
    top: 20px
}

.Logout {
    color: #fff;
    z-index: 2000;
    position: fixed;
    top: 20px;
    right: 40px;
    cursor: pointer;
}

@media (max-width: 1030px) {
    .header {
        padding-top: 15px;
        font-size: 6.4vw;
    }
    .loginName {
        font-size: 30px;
        left: 20px;
    }

    .Logout {
        right: 20px;
        font-size: 30px
    }
}

@media(max-width:770px) {
    .responsive a {
        font-size: 10rem !important;
    }

    .loginName {
        font-size: 20px;
        left: 20px;
    }

    .Logout {
        right: 20px;
        font-size: 20px
    }
}
@media(max-width:550px) {
    .responsive a {
        font-size: 10rem !important;
    }

    .loginName {
        font-size: 10px;
        left: 10px;
    }

    .Logout {
        right: 10px;
        font-size: 10px
    }
}

.responive {
    display: flex;
    /* height:8rem !important; */

}