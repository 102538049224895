.media,.mediaX {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 5px;
    background-color: #282c34;
    border-radius: 10px;
    position: relative;
    font-family: "Montserrat", sans-serif;
    height:420px;
    margin-top: 20px;
    cursor: pointer
}
.mediaX{
    cursor:unset;
    margin-top: 50px;
}
.media:hover {
    background-color: white;
    color: black;
}


@media (max-width: 550px) {
    .media {
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }
}

.poster {
    border-radius: 10px;
}

.title {
    width: 100%;
    text-align: center;
    font-size: 17px;
    padding: 8px 0;
}

.subTitle {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    padding-bottom: 3px;
    
}