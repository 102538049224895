@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,300;1,400&display=swap');

* {
  scroll-behavior: smooth;

}
body{
  font-family: "Montserrat", sans-serif;
}
.app {
  background-color: #39445a;
  color: white;
}

.pageTitle {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 2vw;
  padding: 4px;
  border-radius: 50px;
  color: white;
}

.textAlignCenter {
  text-align: center;
  margin: auto;
}

.trending {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fixing {
  padding-top: 30px;
  margin-top: 110px;
  min-height: 75vh;
}

@media(max-width:1200px){
  .fixing {
    margin-top: 65px;
  }
}
@media (max-width: 1000px) {
  .pageTitle {
    font-size: 6.4vw;
  }
}
@media(max-width:550px){
  .fixing {
    margin-top: 25px;
  }
}